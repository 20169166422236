<script>
import { authMethods } from "@/state/helpers";
import { required } from "vuelidate/lib/validators";
import { userService } from "@/services/user.service";

export default {
  data() {
    return {
      reference: "",
      validatingPayment: false,
      errors: {},
      submitted: false,
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  validations: {
    reference: { required },
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  methods: {
    ...authMethods,
    // Try to register the user in with the email, fullname
    // and password they provided.
    async validateRegistrationPayment() {
      this.$store.dispatch("notification/clear");
      this.errors = {};
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        try {
          this.validatingPayment = true;
          await userService.validatePayment({ reference: this.reference });
          // this.$store.dispatch("notification/success", 'Payment Successful!<br>Please wait while we validate your payment.');
          this.$router.push("/");
          this.validatingPayment = false;
        } catch (error) {
          this.validatingPayment = false;
          switch (error.response.status) {
            case 403:
              this.errors = error.response.data.errors;
              break;
            case 422:
              this.errors = error.response.data.errors;
              break;
            case 500:
              this.$store.dispatch(
                "notification/error",
                error.response.data.message
              );
              break;
            default:
              this.$store.dispatch(
                "notification/error",
                "Something went wrong. Please try again!"
              );
              break;
          }
        }
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div
              class="
                authentication-page-content
                p-4
                d-flex
                align-items-center
                min-vh-100
              "
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img
                              src="@/assets/images/logo-dark.png"
                              height="20"
                              alt="logo"
                            />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">
                          Verify Payment Reference
                        </h4>
                        <p class="text-muted">
                          A "reference" was sent to your email after you made
                          the payment. Please enter it to verify your payment.
                        </p>
                      </div>

                      <div class="p-2 mt-5">
                        <b-alert
                          :variant="notification.type"
                          class="mt-1"
                          v-if="notification.message"
                          show
                          dismissible
                          @dismissed="$store.dispatch('notification/clear')"
                          >{{ notification.message }}</b-alert
                        >

                        <form
                          class="form-horizontal"
                          @submit.prevent="validateRegistrationPayment"
                        >
                          <div class="form-group auth-form-group-custom mb-4">
                            <i
                              class="
                                ri-secure-payment-line
                                auti-custom-input-icon
                              "
                            ></i>
                            <label for="reference">Payment Reference</label>
                            <input
                              v-model="reference"
                              type="text"
                              class="form-control"
                              id="reference"
                              placeholder="Enter Reference"
                              :class="{
                                'is-invalid':
                                  (submitted && $v.reference.$error) ||
                                  errors.reference ||
                                  errors.email,
                              }"
                              autofocus
                            />
                            <div
                              v-if="submitted && $v.reference.$error"
                              class="invalid-feedback"
                            >
                              reference is required.
                            </div>
                            <div class="invalid-feedback" v-if="errors">
                              <span v-if="errors.reference"
                                >{{ errors.reference[0] }} &nbsp;</span
                              >
                              <span v-if="errors.email">{{
                                errors.email[0]
                              }}</span>
                            </div>
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="
                                btn btn-primary
                                w-md
                                waves-effect waves-light
                              "
                              type="submit"
                            >
                              Validate Payment
                              <b-spinner
                                v-show="validatingPayment"
                                small
                                class="align-middle"
                              ></b-spinner>
                            </button>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          Don't have payment reference
                          <router-link
                            tag="a"
                            to="/payment"
                            class="font-weight-medium text-primary"
                            >Make Payment</router-link
                          >
                        </p>
                        <p>
                          © 2020 Cadlands. Crafted with
                          <i class="mdi mdi-heart text-danger"></i> by Encodeup
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>